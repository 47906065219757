import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs';

declare let gtag: Function; // Declare the global gtag function from Google Analytics

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('modalAcceptCookies') modalAcceptCookies?: TemplateRef<any>;

  title = 'STCP Serviços';
  localStorageAcceptCookiesKey: string = 'stcp-accept-cookies';

  constructor(private router: Router, private modalService: NgbModal) { }

  /**
   * Lifecycle hook that is called after Angular has initialized the component.
   * If the user has already accepted cookies, it loads Google Analytics and starts tracking page views.
   * Otherwise, it shows the cookie consent modal to the user.
   */
  ngAfterViewInit(): void {
    const consent = localStorage.getItem('cookie-consent');
    if (consent === 'accepted') {
      this.loadGoogleAnalytics();
      this.trackPageViews();
    } else {
      this.openShowAcceptCookiesModal();
    }
  }

  /**
   * Opens the cookie consent modal to ask for user consent.
   * The modal is configured to be centered, non-dismissable via backdrop or keyboard interaction.
   */
  openShowAcceptCookiesModal() {
    const modalOptions: NgbModalOptions = {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false
    };

    this.modalService.open(this.modalAcceptCookies, modalOptions);
  }

  /**
   * Accepts cookies, stores consent in localStorage, loads Google Analytics,
   * starts tracking page views, and dismisses the modal.
   */
  acceptCookies(): void {
    localStorage.setItem('cookie-consent', 'accepted');
    this.loadGoogleAnalytics();
    this.trackPageViews();
    this.modalService.dismissAll();
  }

  /**
   * Declines cookies, stores the user's decision in localStorage, and dismisses the modal without loading Google Analytics.
   */
  declineCookies(): void {
    localStorage.setItem('cookie-consent', 'declined');
    this.modalService.dismissAll();
  }

  /**
   * Dynamically loads the Google Analytics tracking script.
   * This method creates script elements for both the `gtag.js` library and the `config` settings for tracking.
   */
  loadGoogleAnalytics(): void {
    const gtagScript = document.createElement('script');
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-N2CYVZJ1TN';
    gtagScript.async = true;
    document.head.appendChild(gtagScript);

    const configScript = document.createElement('script');
    configScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-N2CYVZJ1TN');
    `;
    document.head.appendChild(configScript);
  }

  /**
   * Subscribes to Angular Router's `NavigationEnd` events and sends a page view event to Google Analytics
   * each time the user navigates to a new route. This ensures that page views in the SPA are tracked.
   */
  trackPageViews(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        gtag('config', 'G-N2CYVZJ1TN', {
          'page_path': event.urlAfterRedirects
        });
      });
  }
}
