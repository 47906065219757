import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-free-trips-button',
  templateUrl: './free-trips-button.component.html',
  styleUrls: ['./free-trips-button.component.css'],
})
export class FreeTripsButtonComponent {
  @Input() user: User | null = null;

  constructor(private auth: AuthService, private router: Router) {
  }
}
