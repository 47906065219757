import { AUTH0_CONFIG } from "src/app/config/auth0-config";
import { FIREBASE_CONFIG } from "src/app/config/firebase-config";

export const environment = {
  production: false,
  auth: AUTH0_CONFIG,
  firebase: FIREBASE_CONFIG,
  baseUrl: "https://stcp-dev.geolink.pt/",
  registerPortoCardUrl: "https://cartaoporto.services.dev.portodigital.pt/",
  sibsUrl: 'https://spg.qly.site1.sibs.pt/assets/js/widget.js?id='
};
