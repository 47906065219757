import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyEuroPipe } from './currency-euro.pipe';
import { BankReferencePipe } from './bank-reference.pipe';


@NgModule({
  declarations: [
    CurrencyEuroPipe,
    BankReferencePipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CurrencyEuroPipe,
    BankReferencePipe
  ]
})
export class PipesModule { }
