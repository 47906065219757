<div *ngIf="stcpAction" class="form-container">
    <app-input-porto-card [(ngModel)]="stcpAction.portoCard" [isMandatory]="isMandatory && true" [disabled]="disableAll"
        [errorMessage]="'Campo obrigatório'" [showError]="showErrors && !stcpAction.portoCard"
        [validationStatus]="portoCardValidationStatus"></app-input-porto-card>

    <app-input-contact [(ngModel)]="stcpAction.contact" [disabled]="disableAll"
        [class]="isMandatory && (!!stcpAction.contact || !stcpAction.email) ? '' : 'no-mandatory-fields'"
        [isMandatory]="isMandatory && (!!stcpAction.contact || !stcpAction.email)"
        [errorMessage]="'É obrigatório preencher o campo contacto'"
        [invertColor]="true"
        [showError]="showErrors && (!stcpAction.contact && !stcpAction.email)"></app-input-contact>

    <!-- <app-input-email 
        [(ngModel)]="stcpAction.email"
        [disabled]="disableAll"
        [class]="!stcpAction.contact || !!stcpAction.email ? '' : 'no-mandatory-fields'"
        [isMandatory]="!stcpAction.contact || !!stcpAction.email"
        [errorMessage]="'É obrigatório preencher o campo do email ou contacto'"
        [showError]="showErrors && (!stcpAction.contact && !stcpAction.email)"
    ></app-input-email> -->

    <div *ngIf="errorMessage && portoCardValidationStatus == 'invalid'"
        class="text-danger text-center mt-3 w-100 error-message-container">
        **{{ errorMessage }}**
    </div>

    <div *ngIf="showButtons" class="button-group mt-3 text-right">
        <button class="btn btn-danger" (click)="cleanFieldsHandler()">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
            {{ cleanButtonLabel }}
        </button>

        <button *ngIf="!!stcpAction.portoCard && !!stcpAction.contact" class="btn btn-primary"
            (click)="confirmAction()">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            Confirmar
        </button>
        <!-- Botão de confirmação desativado -->
        <button *ngIf="!(!!stcpAction.portoCard && !!stcpAction.contact)" class="btn btn-dark btn-disabled"
            (click)="showErrorsHandler()">
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            Confirmar
        </button>
    </div>
</div>