import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Observable,
  Subject,
  Subscription,
  catchError,
  filter,
  of,
  take,
  tap,
  throwError,
  BehaviorSubject
} from 'rxjs';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { LoadingService } from './loading.service';
import { environment } from '../../environment/environment';


@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private authToken: string = '';
  private baseUrl = environment.baseUrl;
  private onlineStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);


  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
  ) {
    this.onlineStatus$ = new BehaviorSubject<boolean>(navigator.onLine);
    window.addEventListener('online', () => this.updateOnlineStatus());
    window.addEventListener('offline', () => this.updateOnlineStatus());
  }

  private updateOnlineStatus(): void {
    this.onlineStatus$.next(navigator.onLine);
  }

  getOnlineStatus(): Observable<boolean> {
    return this.onlineStatus$.asObservable();
  }

  logoutHandler = () => { };

  get baseUrlValue(): string {
    return this.baseUrl;
  }

  /**
   * @description Format the url to be used in the request
   * @param endpoint
   * @returns
   */
  private formatUrl(endpoint: string): string {
    if (endpoint.startsWith('http')) {
      return endpoint;
    }
    return `${this.baseUrl}${endpoint}`;
  }

  private getRequestHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    // Add credentials header
    headers.append('Access-Control-Allow-Credentials', 'true');

    return headers;
  }

  setAuthToken(token: string) {
    this.authToken = token;
  }

  get<T>(
    endpoint: string,
    params?: HttpParams,
    options?: any,
    loading?: boolean
  ): Observable<T> {
    const url = this.formatUrl(endpoint);
    this.loadingService.startLoading(loading, url);

    return this.http.get<T>(url, { headers: this.getRequestHeaders(), params: params }).pipe(
      tap(() => this.loadingService.stopLoading(loading, url)),
      catchError((error) => throwError(() => this.errorHandler(error, loading, options))),
      // filter((res) => res !== false)
    );
  }

  post<T>(
    endpoint: string,
    body: any,
    options?: any,
    loading?: boolean
  ): Observable<T> {
    const url = this.formatUrl(endpoint);
    this.loadingService.startLoading(loading, url);
    let requestOptions = { headers: this.getRequestHeaders() }
    const httpOptions = options?.httpOptions;

    if (httpOptions) {
      requestOptions = { ...requestOptions, ...httpOptions }
    }
    return this.http.post<T>(url, body, requestOptions).pipe(
      tap(() => this.loadingService.stopLoading(loading, url)),
      take(1),
      catchError((error) => this.errorHandler(error, loading, options)),
      filter((res) => res !== false)
      // finalize(() => this.loadingService.stopLoading())
    );
  }

  put<T>(
    endpoint: string,
    body: any,
    options?: any,
    loading?: boolean
  ): Observable<T> {
    const url = this.formatUrl(endpoint);
    this.loadingService.startLoading(loading, url);
    return this.http.put<T>(url, body).pipe(
      tap(() => this.loadingService.stopLoading(loading, url)),
      take(1),
      catchError((error) => this.errorHandler(error, loading, options)),
      filter((res) => res !== false)
      // finalize(() => this.loadingService.stopLoading())
    );
  }

  patch<T>(
    endpoint: string,
    body: any,
    options?: any,
    loading?: boolean
  ): Observable<T> {
    const url = this.formatUrl(endpoint);
    this.loadingService.startLoading(loading, url);
    return this.http.patch<T>(url, body).pipe(
      tap(() => this.loadingService.stopLoading(loading, url)),
      take(1),
      catchError((error) => this.errorHandler(error, loading, options)),
      filter((res) => res !== false)
      // finalize(() => this.loadingService.stopLoading())
    );
  }

  delete<T>(
    endpoint: string,
    body: any,
    options?: any,
    loading?: boolean
  ): Observable<T> {
    const url = this.formatUrl(endpoint);
    this.loadingService.startLoading(loading, url);
    return this.http.delete<T>(url).pipe(
      tap(() => this.loadingService.stopLoading(loading, url)),
      take(1),
      catchError((error) => this.errorHandler(error, loading, options)),
      filter((res) => res !== false)
      // finalize(() => this.loadingService.stopLoading())
    );
  }

  errorHandler(error: any, loading?: boolean, options?: any): Observable<any> {
    this.loadingService.stopLoading(loading)
    if (error instanceof HttpErrorResponse && error.status === 403) {
      // Handle 403 error (for example, trigger a logout)
      this.logoutHandler();  // Call your logout method from the AuthService
      return of(false);
    }

    if (options?.noShowError) return of(false);
    if (options?.errorFunction) {
      options.errorFunction();
      return of(false);
    }

    const swalErrorOptions: SweetAlertOptions = {
      title: 'Erro!',
      text: 'Erro ao tentar executar a ação, tente novamente mais tarde',
      icon: 'error',
      confirmButtonText: 'Ok',
    };

    if (this.onlineStatus$.value) {
      Swal.fire(swalErrorOptions);
    }
    console.error(error);
    return of(false);
  }

  cancelRequest(subscription: Subscription): void {
    this.loadingService.stopLoading();
    if (subscription && !subscription.closed) {
      subscription.unsubscribe();
    }
  }

  //TEST MODE
  testRequest(response: any, options?: any): Observable<any> {
    this.loadingService.startLoading();
    const subscribe = new Subject<any>();
    setTimeout(() => {
      subscribe.next(response);
    }, 100);
    return subscribe.pipe(tap(() => this.loadingService.stopLoading()));
  }
}
