<div class="footer-container text-white">
    <a class="img-container" href="https://www.stcpservicos.pt/">
        <img src="/assets/img/logo-stcp.png" alt="">
    </a>
    <div class="footer-content-element-container">
        <a class="btn text-white footer-content-element"
            href="https://www.stcpservicos.pt/politica-de-privacidade-e-protecao-de-dados-pessoais">
            <span>Política de Privacidade e Proteção de Dados Pessoais</span>
        </a>
        <div class="footer-content-element footer-content-separator">
            <span>|</span>
        </div>
        <a class="btn  text-white footer-content-element" href="https://www.stcpservicos.pt/politica-de-cookies">
            <span>Política de Cookies</span>
        </a>
        <div class="footer-content-element footer-content-separator">
            <span>|</span>
        </div>
        <a class="btn  text-white footer-content-element" href="https://www.livroreclamacoes.pt/Inicio/">
            <span>Livro de Reclamações Eletrónico</span>
        </a>
        <div class="footer-content-element footer-content-separator">
            <span>|</span>
        </div>
        <a href="https://mais-perto.pt/faqs.pdf" class="btn  text-white footer-content-element">
            <span>FAQS</span>
        </a>
        <div class="footer-content-element footer-content-separator">
            <span>|</span>
        </div>
        <a href="https://mais-perto.pt/condicoes_do_servico_passageiros.pdf"
            class="btn  text-white footer-content-element">
            <span>Normas do Utilizador</span>
        </a>

        <div class="footer-content-element footer-content-separator">
            <span>|</span>
        </div>
        <a href="https://mais-perto.pt/politica_de_reembolso.pdf" class="btn  text-white footer-content-element">
            <span>Políticas de Reembolso</span>
        </a>
        <div class="footer-content-element footer-content-separator">
            <span>|</span>
        </div>
        <a [routerLink]="'/app/contact'" class="btn  text-white footer-content-element">
            <span>Contacto</span>
            <!-- <i class="fa fa-envelope-o" aria-hidden="true"></i> -->
        </a>
    </div>

    <div class="social-icon-container">
        <a href="https://www.linkedin.com/company/stcp-servicos/">
            <div class="social-icon text-white">
                <i class="fa fa-linkedin" aria-hidden="true"></i>
            </div>
        </a>
    </div>
</div>