import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
  switchMap,
  tap
} from 'rxjs';
import { Alert, AlertType } from '../models/alert.model';
import { StcpAction } from '../models/stcp-action';
import { User, UserRole } from '../models/user.model';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { visaCarUsers } from '../constants/visa-car-users';
import { AUTH_TYPE_LOCAL_STORAGE_KEY } from '../config/auth-config';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  userAlerts$: BehaviorSubject<Alert[]> = new BehaviorSubject<Alert[]>([]);
  token$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  validUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // retrieveUserData$: Subject<any> = new Subject<any>(false);

  constructor(private api: ApiService) {
    // this.authService.user$.subscribe((user: User | null) => {
    //   this.user$.next(user);
    // });
    // this.authService.userAuthToken$.subscribe((token: string) => this.retrieveUserData(token));
    this.user$
      .pipe(
        tap((user) => {}),
        switchMap((user) => of(!!user?.isRegistered))
      )
      .subscribe((isValid) => {
        this.validUser.next(isValid);
      });

    this.token$.subscribe((authToken) => {
      if (!authToken) return;
      this.api.setAuthToken(authToken);
      this.retrieveUserAlerts(authToken);
    });

    // this.authService.userAuthToken$.subscribe((token: string) => {
    //   this.token$.next(token);
    // });
  }

  retrieveUserAlerts(userObj: any): void {
    if (!userObj) this.userAlerts$.next([]);
    // this.api.get('alerts').subscribe((response) => {
    //   this.userAlerts$.next(response['msg']);
    // });

    const fakeResponseTypes = Object.values(AlertType)
      .map((value, index) => ({ index, value }))
      .reduce((a: any, b: any) => {
        a[b.index] = b.value;
        return a;
      }, {});

    const fakeResponse: { msg: Alert[] } = {
      msg: Array(10)
        .fill(null)
        .map((_, i) => ({
          id: i.toString(),
          title: `Alert ${i}`,
          message: `Alert ${i} message.`,
          type: fakeResponseTypes[i % Object.keys(fakeResponseTypes).length],
          isDeleted: false,
          isVisualized: false,
          createdAt: new Date(2023, 10, i + 1),
        })),
    };

    this.api
      .testRequest(fakeResponse)
      .subscribe((response) => this.userAlerts$.next(response['msg']));
  }

  isUserValid(user: User | null): Observable<boolean> {
    if (!user) {
      return of(false);
    }
    if(user.type != UserRole.CLIENT) return of(true);
    if(!this.isEmailValid(user.email)) return of(false);
    if(!this.isPortoNumberValid(user.portoCard)) return of(false);
    if(!this.isPhoneNumberValid(user.phoneNumber)) return of(false);
    if(!this.isNameValid(user.name)) return of(false);

    return of(true);
  }

  isEmailValid(email?: string): boolean{
    if(!email) return false;
    if(!email.includes('@')) return false;
    if(!email.includes('.')) return false;
    return true;
  }

  isPortoNumberValid(portoNumber?: string): boolean{
    if(!portoNumber) return false;
    return true;
  }

  isPhoneNumberValid(phonenumber?: string): boolean{
    if(!phonenumber) return false;
    return true;
  }

  isNameValid(name?: string): boolean {
    if(!name) return false;
    return true;
  }
  
  isNifValid(name?: string): boolean {
    return true;
  }

  portoCardCheck(stcpAction: StcpAction, user?: User): Observable<any> {
    const url = 'portocard/check';
    const body: any = {
      card_id: parseInt(stcpAction.portoCard),
      contact: stcpAction.contact,
    };

    if(user){
      body['user_id'] = user.id;
    }

    if(stcpAction.email){
      body['email'] = stcpAction.email;
    }

    if(stcpAction.companion){
      body['companion'] = stcpAction.companion;
    }

    return this.api.post(url, body).pipe(map((res: any) => {
      return {
      error: res['error'] || null,
      user: res['user'] || null
    }
  }));
  }

  createUserFromRequest(userObject: any): User{
    // const userType = UserRole.OPERATOR
    
    const user = new User({
      email: userObject.email,
      externalId: userObject.external_id,
      id: userObject.id,
      isRegistered: userObject.is_registered,
      type: this.createUserRole(userObject),
      name: userObject.name,
      phoneNumber: userObject.phone_number,
      portoCard: userObject.porto_card,
      picture: userObject.picture ?? '',
      nif: userObject.nif,
      companions: userObject.companions
    });

    return user;
  }

  createUserRole({type, id}: any): UserRole{
    if(id in visaCarUsers){
      return UserRole.VISA_CAR_OPERATOR;
    }

    switch(type){
      case 'operator': return UserRole.OPERATOR;
      case 'client': return UserRole.CLIENT;
      case 'admin': return UserRole.ADMIN;
      case 'manager': return UserRole.MANAGER;
    }

    return UserRole.OPERATOR
  }

  signOut = () => {}

  retrieveUserFromLogin(): void {
    this.api.get('users/me').pipe(
      map((res: any) => res.user),
      catchError((error: any)  => {
        this.signOut();
        return of(false)
      })
      )
      .subscribe((userObject: any) => {
        if (!userObject) throw 'retrieve user ERROR'
        const user: User = this.createUserFromRequest(userObject);
        
        this.user$.next(user);
      });
  } 

  userUpdate(newUser: User){
    const url = 'users/update';
    const body = {
      "email": newUser.email,
      "phone_number": newUser.phoneNumber,
      "nif": newUser.nif,
    };
    return this.api.post(url, body).pipe(map(({user}: any): User => this.createUserFromRequest(user)));
  }

  // createUserFromPortoCard(portoCard: any, stcpAction: StcpAction): User {
  //   return new User(
  //     portoCard.name,
  //     portoCard.name,
  //     '',
  //     new Date(portoCard.data_nascimento),
  //     portoCard.email,
  //     true,
  //     '1',
  //     stcpAction.portoCard,
  //     stcpAction.contact || '',
  //     UserRole.CLIENT
  //   );
  // }
}
