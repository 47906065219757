import { Injectable } from '@angular/core';
import { Point } from '../models/point.model';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { ApiService } from './api.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Place } from '../models/place';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PointService {
  pointsSubject = new BehaviorSubject<Point[]>([]);
  constructor(private api: ApiService) {}

  createPointFromRequest(response: any): Point {
    return {
      id: response['id'],
      lat: response['lat'],
      lon: response['lon'],
      name: response['name'],
      type: response['type'],
      formattedAddress: response['formatted_address'],
    };
  }

  createPoints(response: any): Point[] {
    const points: Point[] = [];
    for (let point of response) {
      points.push(this.createPointFromRequest(point));
    }
    return points;
  }

  getPointsAll(): Observable<Point[]> {
    const endpoint = 'points/stcp';
    return this.api.get(endpoint).pipe(
      map(({Points: pointsObject}: any) => this.createPoints(pointsObject)),
      tap((points: Point[]) => this.pointsSubject.next(points))
    );
  }

  getPointsConnections(point: Point): Observable<any> {
    const endpoint = 'points/connections';
    const params = new HttpParams().set('id', point.id);

    return this.api
      .get(endpoint, params, {}, false)
      .pipe(map((response: any) => response['Points']));
  }

  places(text: string, sessionToken: string): Observable<Point[]> {
    const endpoint = 'geom/places';
    const lat = 41.1633499;
    const lon = -8.5854796;

    const body = {
      text,
      session_token: sessionToken,
      lat,
      lon
    }

    const options = {};

    const loading = false;

    return this.api.post(endpoint, body, options, loading).pipe(
      map((response: any) => response['Places']),
      map((msg: any) =>
       ( msg['predictions'] || []).map((place: any) => {
          return {
            id: '',
            lat: place['location']['lat'],
            lon: place['location']['lng'],
            name: place['description'],
            type: '',
            formattedAddress: place['description'] + '. ' + place['structured_formatting']['secondary_text'].replace(', Portugal', '.')
          };
        })
      )
    );
  }

  // placesDetails(placeId: string, sessionToken: string): Observable<Point> {
  //   const endpoint = 'https://taxi.geolink.pt:23000/api/google/places_details';
  //   const USER_TOKEN = 'b97c95df-5f99-4e6e-a413-a0d74a8764e5';

  //   const formData = new FormData();
  //   formData.append('place_id', placeId);
  //   formData.append('session_token', sessionToken);

  //   const headers = new HttpHeaders({
  //     'X-Forwarded-For': USER_TOKEN, // Replace USER_TOKEN with your actual token
  //   });

  //   const httpOptions = {
  //     headers: headers,
  //   };

  //   const options = {
  //     httpOptions,
  //   };

  //   const enptyPoint = JSON.stringify({
  //     id: placeId,
  //     lat: 0,
  //     lon: 0,
  //     name: '',
  //     type: '',
  //     formattedAddress: '',
  //   })

  //   return this.api.post(endpoint, formData, options).pipe(
  //     map((response: any) => response['msg']),
  //     map((msg: any) => {
  //       if (msg['status'] === 'INVALID_REQUEST') return JSON.parse(enptyPoint);
  //       return {
  //         id: placeId,
  //         lat: msg['result']['geometry']['location']['lat'],
  //         lon: msg['result']['geometry']['location']['lng'],
  //         name: msg['result']['address_components'][0]['long_name'],
  //         type: '',
  //         formattedAddress: msg['result']['address_components'][0]['long_name'],
  //       };
  //     })
  //   );
  // }

  requestRoute(startLocation: Point, endLocation: Point): Observable<any> {
    // const endpoint = 'https://taxi.geolink.pt:23000/api/google/routes';
    const endpoint = 'geom/routes';

    // const formData = new FormData();
    // formData.append('origin_lat', startLocation.lat.toString());
    // formData.append('origin_lon', startLocation.lon.toString());
    // formData.append('destination_lat', endLocation.lat.toString());
    // formData.append('destination_lon', endLocation.lon.toString());
    // formData.append('is_ndrive', true + '');

    const body = {
      origin_lat: startLocation.lat,
      origin_lon:  startLocation.lon,
      destination_lat: endLocation.lat,
      destination_lon: endLocation.lon,
    }

    const options = {};

    return this.api
      .post(endpoint, body, options, false)
      .pipe(map((response: any) => response['Places']));
  }


  reverseGeocode(lat: number, lng: number): Observable<Point> {
    const endpoint = 'geom/rev_geo';

    const body = {
      lat,
      lon: lng,
    }

    const options = {};

    const loading = false;

    return this.api.post(endpoint, body, options, loading).pipe(
      map(({RevGeo}: any) => ({
          id: '',
          lat: lat,
          lon: lng,
          name: RevGeo['results'][0]['formatted_address'],
          type: '',
          formattedAddress: RevGeo['results'][0]['formatted_address'],
      }))
    );
  }
}
