import { Companion } from "./companion.model";

/**
 * Enumeration representing user roles.
 */
export enum UserRole {
  CLIENT = 'CLIENT',
  OPERATOR = 'OPERATOR',
  VISA_CAR_OPERATOR = 'VISA_CAR_OPERATOR',
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
}

/**
 * @description
 * Class representing a user.
 */
export class User {
  /** @property {string} email - Email of the user. */
  email: string;

  /** @property {string} externalId - External identifier of the user. */
  externalId: string;

  /** @property {string | undefined} name - Name of the user (optional). */
  name?: string;

  /** @property {number} id - Unique identifier for the user. */
  id: number;

  /** @property {boolean} isRegistered - Indicates whether the user is registered. */
  isRegistered: boolean;

  /** @property {string | undefined} phoneNumber - Phone number of the user (optional). */
  phoneNumber?: string;

  /** @property {string | undefined} portoCard - Porto card of the user (optional). */
  portoCard?: string;

  /** @property {UserRole} type - User role (enum). */
  type!: UserRole;

  /** @property {string | undefined} picture - Picture of the user (optional). */
  picture?: string;

  /** @property {string | undefined} nif - Tax Identification Number (optional). */
  nif?: string;

  /** @property {Companion[] | undefined} companions - Array of companions associated with the user (optional). */
  companions?: Companion[];
  
  /** @property {number | undefined} number - Number of free trips available to the user */
  freeTrips?: number;



  /**
   * @description
   * Constructor for the User class.
   * @param {object} param0 - Object containing user properties.
   */
  constructor({
    email,
    externalId,
    name,
    id,
    isRegistered,
    phoneNumber,
    portoCard,
    type,
    picture,
    nif,
    companions,
    freeTrips
  }: any) {
    this.email = email;
    this.externalId = externalId;
    this.name = name;
    this.id = id;
    this.isRegistered = isRegistered;
    this.phoneNumber = phoneNumber;
    this.portoCard = portoCard;
    this.type = type;
    this.picture = picture;
    this.nif = nif;
    this.setCompanions(companions);
    this.freeTrips = freeTrips;
  }

  /**
   * @description
   * Method to set companions for the user.
   * @param {any | any[]} companions - Companions associated with the user.
   */
  setCompanions(companions?: any | any[]) {
    this.companions = [];
    if (!companions) return;

    for (let { name, porto_card: portoCard, n_free_trips } of companions) {
      const companion: Companion = { name, portoCard, freeTrips: n_free_trips };
      this.companions.push(companion);
    }
  }
}
