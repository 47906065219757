import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  loadingCount$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  loopTimeout: any;

  constructor() {}

  // Increment the loading count
  startLoading(loading: boolean = true, url?: string) {
    if (!loading) return
    const newValue = this.loadingCount$.value + 1;
    if (newValue < 0) return this.loadingCount$.next(1);
    this.loadingCount$.next(this.loadingCount$.value + 1);
    this.loopTimeout = setTimeout(() => this.loadingCount$.next(0), 2000);
  }

  // Decrement the loading count
  stopLoading(loading: boolean = true, url?: string) {
    if (!loading) return
    const newValue = this.loadingCount$.value - 1;
    if (newValue < 0) return this.loadingCount$.next(0);
    this.loadingCount$.next(newValue)
  }
}
