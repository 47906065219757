
<div *ngIf="user && user.freeTrips && user.freeTrips > 0" ngbDropdown class="d-inline-block">
  
  <!-- LAYOUT 1 -->
  <button type="button" class="no-arrow btn" id="dropdownUser" ngbDropdownToggle>
    <i class="fa fa-bell bell-alert text-white" aria-hidden="true"></i>
    <span class="badge badge-pill badge-danger" style="right: -5px!important;">{{ user.freeTrips }}</span>
  </button>

  <!-- LAYOUT 2 -->
  <!-- <button type="button" class="no-arrow btn btn-light" id="dropdownUser" ngbDropdownToggle>
    <i class="fa fa-taxi" aria-hidden="true"></i>
    Viagens Gratuitas
    <span class="badge badge-pill badge-danger">{{ user.freeTrips }}</span>
  </button> -->
  <div ngbDropdownMenu aria-labelledby="dropdownUser" class="drop-menu-options-container">
    <p ngbDropdownItem class="mb-0">
      <i class="fa fa-taxi" aria-hidden="true"></i>
      Tem {{ user.freeTrips }} {{ user.freeTrips > 1  ? ' viagens' : ' viagem'}} grátis.
    </p>
  </div>
</div>
